import consoleLogger from '../../../../modules/console-logger'
import useScript from 'react-script-hook'
import errorLog from '../../../../modules/error-log'
import isServerSide from '../../../../modules/is-server-side'

const LoadScript = () => {
  const [consoleLog] = consoleLogger('Sourcepoint')
  consoleLog('start', '', 'LoadScript')

  const [, error] = useScript({
    src: 'https://cdn.privacy-mgmt.com/wrapperMessagingWithoutDetection.js',
    onload: () => {
      consoleLog('1 first start - execute messaging - window variable: ', window._sp_)
      const isExecuteMessagingFunction = typeof window?._sp_?.executeMessaging === 'function'
      const isSpScript = window?._sp_
      if (!isSpScript || !isExecuteMessagingFunction) {
        return
      }
      window?._sp_?.executeMessaging();
    },
  })

  if (error) {
    errorLog({ level: 'fatal', info: error.message })
    return false
  }

  return true
}

export default LoadScript
