import './wdyr'
import React, { useEffect } from 'react'
import Theme from './config/themes/'
import ErrorBoundary from './topics/Error/widgets/ErrorBoundary'
import moment from 'moment'
import ScrollToTop from './components/ScrollToTop'
import ThemeProviderResponsive from './components/ThemeProviderResponsive'
import ReactGA from 'react-ga'
import isStolApp from './modules/is-stolapp'
import Piano from './vendor/Piano'
import AutomaticLogin from './layouts/Default/components/AutomaticLogin'
import './modules/polyfills'
import isServerSide from './modules/is-server-side'
import { UserProvider } from './components/ContextUser'
import { createCallbackFunction } from './bundles/BundleScriptScheduler'
import * as Sentry from '@sentry/react'
import errorTracker from './modules/error-tracker'
import { MenuProvider } from './components/ContextMenu'
import { getStoredItem } from './modules/stored-item'
import runOneSignal from './vendor/OneSignal'
import DevToolsQuery from './components/DevToolsQuery'
import loadable from '@loadable/component'
import Sourcepoint from './vendor/Sourcepoint'
const Routes = loadable(() => import(/* webpackChunkName: "Routes", webpackPrefetch: true */ './components/Routes'))
errorTracker({ environment: 'STOLReact' })

const App = ({ ...props }) => {
  useEffect(() => {
    moment.locale('de')
  }, [])

  useEffect(() => {
    if (!isStolApp()) {
      try {
        runOneSignal()
      } catch (e) {
        console.log("OneSignal couldn't be loaded", e.message)
      }
    }
  })

  if (!isServerSide()) {
    createCallbackFunction()
    ReactGA.initialize(process.env.RAZZLE_UA_TRACKING, {
      testMode: isStolApp(),
    })
    ReactGA.set({ anonymizeIp: true })
    ReactGA.ga('set', 'anonymizeIp', true)
  }

  return (
    <ErrorBoundary view="app" {...props}>
      <Piano />
      <AutomaticLogin />
      <Sourcepoint />
      <ScrollToTop>
        <ThemeProviderResponsive theme={Theme}>
          <UserProvider>
            <MenuProvider>
              <Routes />
              {getStoredItem('dlabQueryDevTools', true) === 'true' && <DevToolsQuery />}
            </MenuProvider>
          </UserProvider>
        </ThemeProviderResponsive>
      </ScrollToTop>
    </ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
